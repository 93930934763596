import { ConfigProvider } from "antd";
import CardImg from "assets/3free_img.png";
import FacebookImg from "assets/facebook2.png";
import LinkIcon from "assets/link.svg";
import LinkedinImg from "assets/linkedin2.png";
import TwitterImg from "assets/twitter2.png";
import { varIs, varLabel } from "common/var";
import {
  Alert,
  Button,
  Col,
  CountryFlag,
  DatePicker,
  Progress,
  Row,
  SuccessNotification,
  TablePanel,
  Tag,
  UserAvatar
} from "components/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { callGetApiWithAuth } from "utils/api";
import { asPrice, asDate } from "utils/text";

export default function PELeaderboardPage() {
  const myUser = useSelector((state) => state.auth.user);
  const theme = useSelector((state) => state.ui.theme);
  // const [qualUsers, setQualUsers] = useState([]);
  // const [subscriptions, setSubscriptions] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [searchParam, setSearchParam] = useState({
    date: moment().format("YYYY-MM"),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onFormInput = (field, value) => {
    setSearchParam({
      ...searchParam,
      [field]: value,
    });
  };

  const onGetTableData = (res) => {
    setIsLoading(false);
    setTableData(res.data);
  };

  const onFailTableData = () => {
    setIsLoading(false);
  };

  const loadTableData = (searchParam0) => {
    setIsLoading(true);

    const params = {
      ...searchParam0,
    };

    const q = Object.keys(params)
      .map((k) => k + "=" + params[k])
      .join("&");

    callGetApiWithAuth(
      `akashx/leaderboard/pes?${q}`,
      onGetTableData,
      onFailTableData
    );
  };

  const handleSearch = () => {
    loadTableData(searchParam);
  };

  useEffect(() => {
    handleSearch();
  }, []);

  // const onGetFree3Data = ({ data }) => {
  //   setQualUsers([...data.qual_users]);
  //   setSubscriptions([...data.subscriptions]);
  // };
  // const getFree3Data = () => {
  //   callGetApiWithAuth(`akashx/home/three_free_track_new`, onGetFree3Data);
  // };

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://www.akashx.com/${myUser?.username}`);
    SuccessNotification("Copied on clipboard");
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     $('.owl-banner').owlCarousel({
  //       loop: true,
  //       autoplay:true,
  //       slideTransition: 'linear',
  //       autoplayHoverPause: false,
  //       items: 1,
  //     })
  //   }, [1000])
  // }, [])

  // useEffect(() => {
  //   getFree3Data();
  // }, []);

  return (
    <Wrapper>
      <div className="table-wrap">
        <TablePanel
          data={tableData}
          loading={isLoading}
          toolbar={
            <div
              className="d-flex align-items-center flex-wrap"
              style={{ gap: "8px" }}
            >
              <ConfigProvider theme="dark">
                <DatePicker
                  onChange={(_, v) => { if (v === '') return; onFormInput("date", v) }}
                  picker="month"
                  value={moment(searchParam.date)}
                />
              </ConfigProvider>

              <Button loading={isLoading} onClick={handleSearch}>
                Search
              </Button>
            </div>
          }
          columns={[
            {
              title: "#",
              key: "s_no",
              render: (_, __, index) => <span>{index + 1}</span>,
            },
            {
              title: "Name",
              key: "user",
              render: (_, record) => <UserAvatar
                image={record.user.image}
                title={`${record.user.first_name} ${record.user.last_name}`}
              />,
            },
            {
              title: "Country",
              key: "country",
              render: (_, record) => <div>
                <CountryFlag country={record.user.country} />
              </div>,
            },
            {
              title: "Personal Enrollments",
              key: "npe",
              render: (_, record) => <span>{(record.npe)}</span>,
            },
            {
              title: "Business Volume",
              key: "npev",
              render: (_, record) => <span>{(record.npev)}</span>,
            },
          ]}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .free3-card {
    border-radius: 20px;
    background: ${(props) => props.theme.palette.cardBg};
    border: 1px solid ${(props) => props.theme.palette.cardBorder};
    padding: 23px;
    margin: 20px 0;
    height: 100%;
  }
  .site-card {
    border-radius: 20px;
    background: ${(props) => props.theme.palette.cardBg};
    border: 1px solid ${(props) => props.theme.palette.cardBorder};
    padding: 25px 20px;
    margin-bottom: 20px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .img-wrap {
      width: 800px;
      @media (max-width: 1366px) {
        width: 400px;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
    .desc-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 20px 0px;
      width: calc(100% - 840px);
      @media (max-width: 1366px) {
        width: calc(100% - 440px);
      }
      @media (max-width: 768px) {
        width: 100%;
        margin-top: 12px;
      }
      h4 {
        color: ${(props) => props.theme.palette.text};
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 9px;
      }
      p {
        color: ${(props) => props.theme.palette.text};
        font-size: 15px;
        margin-bottom: 10px;
        line-height: 22px;
        font-weight: 400;
      }
      .lnk-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        label {
          color: ${(props) => props.theme.palette.secondaryText};
        }
      }
      button {
        border-radius: 12px;
        background: #c1954a;
        width: 140px;
        padding: 8px 0;
        border: 0 none;
        color: #0d0d0d;
        font-size: 14px;
        img {
          margin-right: 8px;
        }
      }
    }
    .social-wrap {
      display: flex;
      margin: 8px 0;
      img {
        margin-right: 8px;
        cursor: pointer;
      }
    }
  }
  .table-wrap {
    .cardRoot {
      color: ${(props) => props.theme.palette.text};
      border-radius: 15px;
      background: ${(props) => props.theme.palette.cardBg};
    }
    // .panelHead {
    //   display: none;
    // }

    // .tableWrapper {
    //   margin-top: -20px;
    // }

    .ant-table thead tr th {
      background: #2d2921;
      color: ${(props) => props.theme.palette.th};
    }
    .ant-table {
      color: ${(props) => props.theme.palette.text};
      background-color: transparent;
    }
    .tableWrapper .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: transparent;
      opacity: 0.8;
    }
    .ant-table-thead > tr > th {
      border-color: #efedf91a;
    }
    .ant-table-tbody > tr > td {
      border-color: #efedf91a;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: transparent;
    }
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
      background: transparent;
    }
  }
  .line-image {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .free-member-ship {
    display: flex;
  }

  .rank-progress-container {
    // margin-top: 20px;
    .progress-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #7f8fa3;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #c1954a;
      }
      .payment-tag {
        .active {
          background: rgba(0, 171, 85, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #36b37e;
        }
        .failed {
          background: rgba(255, 86, 48, 0.08) !important;
          border-radius: 6px;
          font-weight: 700;
          border: none;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #ff5630;
        }
      }
    }
    .rank-progress {
      .ant-progress-inner,
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }
`;
